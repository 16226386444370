<script src="../mixins/WhoWeAreTimeline.js"></script>
<template>
    <section class="fandom" v-if="data">

        <div class="fandom__grid" v-if="data">
            <div class="fandom__grid-title" v-scroll-reveal="{ distance: '0px', beforeReveal: revealTop }">
                <p class="subtitle">We Love Your</p>
                <p class="title">Fandom!</p>
            </div>

            <div class="fandom__grid-submit" v-scroll-reveal="{ origin: 'right', delay: 1000 }">
                <p class="message">Tweet us<br>
                    <a href="https://twitter.com/SonySantaMonica" target="_blank">@SonySantaMonica</a> or</p>
                <a href="mailto:sms@sony.com">
                    <div class="btn btn--red-outline">
                        <span>SUBMIT YOURS!</span>
                    </div>
                </a>
            </div>

            <div class="fandom__grid-submit grid-submit-bottom" v-scroll-reveal="{ origin: 'right' }">
                <p class="message">Tweet us<br>
                    <a href="https://twitter.com/SonySantaMonica" target="_blank">@SonySantaMonica</a> or</p>
                <a href="mailto:sms@sony.com">
                    <div class="btn btn--red-outline">
                        <span>SUBMIT YOURS!</span>
                    </div>
                </a>
            </div>

            <div v-for="(item, i) in data.assets" :class="`fandom__grid-cell ${item.type == 'image' ? 'still' : 'video'} cell-${i+1}`" :key="item.id" v-scroll-reveal>
                <router-link :to="`${item.id}`" append>
                    <div class="image" :style="{ 'background-image': 'url(' + item.thumb + ')', 'background-position': item.background_position.x + ' ' + item.background_position.y }"></div>
                </router-link>
            </div>

        </div>

        <!-- HIDE FOREVER -->
        <div v-if="1 == 2" class="fandom__twitter-block">

            <div class="tweet-wrapper" v-for="tweet in data.tweets" :key="tweet.id" v-html="tweet.embed"></div>

            <!-- <blockquote class="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">8 of the Valkyries are down! It&#39;s time to go and meet the queen 👊<br><br>Portraits: <br>• Gondul<br>• Geirdriful<br>• Olrun<br>• Eir<br><br>Portraits continued in next thread...<a href="https://twitter.com/hashtag/Godofwar?src=hash&amp;ref_src=twsrc%5Etfw">#Godofwar</a> <a href="https://twitter.com/hashtag/GOWPhotoMode?src=hash&amp;ref_src=twsrc%5Etfw">#GOWPhotoMode</a> <a href="https://twitter.com/hashtag/VirtualPhotography?src=hash&amp;ref_src=twsrc%5Etfw">#VirtualPhotography</a> <a href="https://twitter.com/hashtag/VGPUnite?src=hash&amp;ref_src=twsrc%5Etfw">#VGPUnite</a> <a href="https://t.co/LrkccbxAax">pic.twitter.com/LrkccbxAax</a></p>&mdash; Raffu (@Raffu42) <a href="https://twitter.com/Raffu42/status/1136354740441440257?ref_src=twsrc%5Etfw">June 5, 2019</a></blockquote>

            <blockquote class="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">Is this the real life? Is this just fantasy? 🔥🔥🔥🔥<br><br>IG credit - <a href="https://t.co/1wbjN1Q0Le">https://t.co/1wbjN1Q0Le</a> <a href="https://t.co/Cj6u5Habpz">pic.twitter.com/Cj6u5Habpz</a></p>&mdash; Santa Monica Studio is Watching Raising Kratos (@SonySantaMonica) <a href="https://twitter.com/SonySantaMonica/status/1136285865364246531?ref_src=twsrc%5Etfw">June 5, 2019</a></blockquote>

            <blockquote class="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">Wait for it....<br><br>For the world&#39;s greatest Dad, or any other, this <a href="https://twitter.com/hashtag/GodofWar?src=hash&amp;ref_src=twsrc%5Etfw">#GodofWar</a> coffee mug from <a href="https://twitter.com/Fangamer?ref_src=twsrc%5Etfw">@Fangamer</a> is 🔥🔥🔥🔥🔥🔥<br><br>Check out their brand new God of War merch collection here: <a href="https://t.co/tJ3H45xbBO">https://t.co/tJ3H45xbBO</a> <a href="https://t.co/LoIIcxraDA">pic.twitter.com/LoIIcxraDA</a></p>&mdash; Santa Monica Studio is Watching Raising Kratos (@SonySantaMonica) <a href="https://twitter.com/SonySantaMonica/status/1136738456716107776?ref_src=twsrc%5Etfw">June 6, 2019</a></blockquote> -->

        </div>

        <site-footer />

        <transition name="fade">
            <router-view parentName="fandom" :data="data.assets" v-if="data" />
        </transition>

    </section>
</template>

<script>
    // swap for other button
    // import DiamondButton from '../components/buttons/DiamondButton'
    import Footer from '@/components/Footer'
    import Vue from 'vue'
    import axios from 'axios'
    import Meta from '../mixins/Meta.js'
    import gsap, {Power2} from 'gsap'

    export default {
        mixins: [Meta],
        components: {
            // DiamondButton,
            'site-footer': Footer
        },
        data() {
            return {
                data: null
            }
        },
        // created() {
        //     let ckeditor = document.createElement('script');
        //     ckeditor.setAttribute('src',"//platform.twitter.com/widgets.js");
        //     document.head.appendChild(ckeditor);
        // },
        mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                axios.get(this.$json('/data/fandom.json'))
                    .then(response => {
                        this.data = response.data;
                        this.setMetaData(this.data.meta);
                        this.$sectionLoaded();
                        Vue.nextTick(() => {
                            // initalize tweets
                            //twttr.widgets.load(document.querySelectorAll('.tweet-wrapper'));
                        });
                        this.$checkAgeGate(this.data.ageGate);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            revealFanArt() {
                var fanArt = gsap.timeline({})
                // let gridItems = ArrayHelper.shuffle($('.great-ideas__apply-images__grid > div'));
                let gridItems = $('.great-ideas__apply-images__grid > div');
                fanArt.from(gridItems, 0.6, {
                    opacity: 0,
                    scale: 0.5,
                    stagger:0.1
                });
            },
            revealTop() {
                var topHeadline = gsap.timeline({})

                topHeadline.from($('.fandom__grid-title > .subtitle'), .5, {
                    x: 50,
                    opacity: 0,
                    ease: Power2.easeInOut
                }, .25);

                topHeadline.from($('.fandom__grid-title > .title'), .5, {
                    x: 50,
                    opacity: 0,
                    ease: Power2.easeInOut
                }, .5);

                topHeadline.from($('.fandom__grid-submit > .btn'), .5, {
                    x: -50,
                    opacity: 0,
                    ease: Power2.easeInOut
                }, .75);

            }
        },
    }
</script>
